<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true }"></v-header>
        </div>
        <div>
            <!-- <img v-for="(item,index) in list" :key="index" :src="item.headImgUrl"/> -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div v-for="(item,index) in list" :key="index" class="item" @click="gotoDetail(item.dashiId)">
                    <p>{{ headerTitle }}</p>
                    <van-image
                        width="9.6rem"
                        height="7rem"
                        fit="fill"
                        :src="item.headImgUrl"
                        style="padding:0.1rem;"
                    />
                    <hr/>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import vHeader from '@/components/header.vue'

export default {
    components:{
        vHeader
    },
    data(){
        return{
            title: this.$route.meta.title,
            headerTitle:'',
            type: this.$route.query.type,
            lotteryKind: this.$route.query.lotteryKind,
            buttonlist:['千位','百位','十位','个位'],   
            pagination: {
                pageNo: 1,
                pageSize: 10,
            }, 
            list:[],
            loading: false,
            finished: false,
            
        }
    },
    methods:{
        
        async onLoad(){

            let data = {
                firstKind: 2, //1代表大师榜 2代表大师规律
                secondKind: parseInt(this.type)+5,
                lotteryKind: this.lotteryKind,
                pageNo: this.pagination.pageNo,
                pageSize: this.pagination.pageSize,
                sortBy: 'dashi_id desc',
                systemId: 100001, 
                }
                const res = await this.$HTTP.post(this, 'API_MASTER_LIST', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token }}, true)
               
                this.loading = false
                if (res.statusCode === 200) {
                    // console.log('大师规则信息：',res)
                    this.pagination.pageNo += 1
                    this.list=this.list.concat(res.data)
                    
                }
                if (this.list.length >= res.totalSize * 1) {
                        this.finished = true;
                }
                console.log('list:',this.list)
        },
        gotoDetail(id){
            this.$router.push({
                name: 'masterruledetail',
                query:{
                    id: id,
                }
            })
        },
    },
    mounted(){
        // this.getmasterList()
    },
    created(){
        this.title='大师规律('
        if(this.lotteryKind===5){
            this.title=this.title+'排列五)'+this.buttonlist[this.type]
        }else{
            this.title=this.title+'七星彩)'+this.buttonlist[this.type]
        }
        this.headerTitle='大师规律'
        if(this.lotteryKind===5){
            this.headerTitle=this.headerTitle+'排列五【'+this.buttonlist[this.type]+'】'
        }else{
            this.headerTitle=this.headerTitle+'七星彩【'+this.buttonlist[this.type]+'】'
        }
    }
}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
.item{
    background-color: white;
    p{
        font-size: 0.5rem;
        font-weight: 600;
        text-align: center;
        padding: 0.2rem 0;
        .title{
            margin: 0.1rem 1rem;
        }
        .author{
            margin: 0.1rem 1rem;

        }
        
    }
    img{
        width: 10rem;
        height: 10rem;
        padding: 0.2rem;
    }
}
</style>